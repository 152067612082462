const routes = [
  {
    path: ["/thankyou"],
    exact: true,
    component: "Thankyou",
  },
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },

  {
    path: ["*"],
    exact: true,
    component: "Home",
  },
];

export default routes;
